import {
    required,
    minLength,
    maxLength,
    alpha
  } from 'vuelidate/lib/validators';
  
  import { alphaNumSpecials } from '../funciones';

  export default () => {
    return {
      user: {
        Login: { required, alpha, minLength: minLength(4), maxLength: maxLength(50) },
        Password: { required, alphaNumSpecials, minLength: minLength(6), maxLength: maxLength(20) },
        CompanyId: { required },           
      },  
      connection: { required }      
    }
  }
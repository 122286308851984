//Data:
function data() {
  return {
    showPassword: false,
  }
}

//Meodos:
function inputClasses(field) {
  return {
    'es-valido': field.$dirty ? !field.$error : null,
    'es-invalido': field.$dirty ? field.$error : null
  }
}
function togglePassword() {
  this.showPassword = !this.showPassword;
}

//Computeds
function inputType() {
  return this.showPassword?'text':'password';
}

export default {
  data,
  methods: {
    inputClasses,
    togglePassword
  },
  computed: {
    inputType
  }
}
const connectionsList = () => {
  if(process.env.VUE_APP_REPOSITORY == 'developer') {
    return [
      {
        label: 'DEVELOP',
        value: 'http://pruebassegop.softicompany.com/',
      }
    ]
  }
  if(process.env.VUE_APP_REPOSITORY == 'production') {
    return [
      {
        label: 'LOCAL REPOSITORY',
        value: 'http://192.168.10.111/',
      },
      {
        label: 'CLOUD REPOSITORY',
        value: 'http://siaopbe.softicompany.com/',
      }
    ]
  }

  return [];
};

export { 
  connectionsList,
};